
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Item } from './types'

@Component({
  name: 'SerialVolIss',
})
export default class SerialVolIss extends Vue {
  @Prop({ required: true }) volIssFormatted!: Item[];
  @Prop({ required: true }) dataType!: string;
  @Prop({ required: false, default: false }) internal!: boolean;
  @Prop({ required: false, default: false }) small!: boolean;

  //
  // METHODS
  //
  searchByVolIss(item: Item) {
    if (item.to) {
      if (this.$route.query.last !== item.id) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            last: item.id,
          },
        })
      }
      this.$router.push(item.to)
    }
  }
}
